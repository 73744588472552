import axios from 'axios'
import {Utility} from "../api/utilities";

//const BASE_URI = 'http://localhost:8081/';
const BASE_URI = 'https://goapi.steadycontent.com/';
//const BASE_URI = 'http://devgoapi.steadycontent.com/';

const client = axios.create({
    baseURL: BASE_URI,
    json: true
});

export const MainPageAPIClient = {

    createLead: function (email, name,type, funnel, phone, website, niche, keyword, source, status ) {
        const data = new FormData();
        data.append('query', "mutation M { createLead: createLead(email: " + email + ", name: " + name +", type: " + type +", funnel: " + funnel +", phone: " + phone +", website: " + website +", niche: " + niche +", keyword:" + keyword + ", source:" + source + ", status:" + status + " ) }");


        return this.perform('post', '/graphql' , data, 'multipart/form-data');
    },

    deleteLead: function (recId) {
        const data = new FormData();
        data.append('query', "mutation M { deleteLead: deleteLead(RecId: " + recId + " ) }");


        return this.perform('post', '/graphql' , data, 'multipart/form-data');
    },

    getAllTasksSaleApp: function (take, skip, sort, sorttype, search, startDate, endDate, statuses, guid) {
        const data = new FormData();
        data.append('Key', '345%^SDFS781');
        data.append('take', take);
        data.append('skip', skip);
        data.append('sort', sort);
        data.append('sorttype', sorttype);
        data.append('search', search);
        data.append('startDate', Utility.replaceAll(startDate, "-", "/") );
        data.append('endDate', Utility.replaceAll(endDate, "-", "/"));
        data.append('statuses', statuses);
        data.append('guid', guid);

        return this.perform('post', '/saleapp/GetAllTasksSaleApp' , data, 'multipart/form-data');
    },

    countAllTasksSaleApp: function (search, startDate, endDate, statuses, guid) {
        const data = new FormData();
        data.append('Key', '345%^SDFS781');
        data.append('search', search);
        data.append('startDate', Utility.replaceAll(startDate, "-", "/") );
        data.append('endDate', Utility.replaceAll(endDate, "-", "/"));
        data.append('statuses', statuses);
        data.append('guid', guid);

        return this.perform('post', '/saleapp/CountAllTasksSaleApp' , data, 'multipart/form-data');
    },

    countLeadStatusSaleApp: function (startDate, endDate) {
        const data = new FormData();
        data.append('Key', '345%^SDFS781');
        data.append('startDate', Utility.replaceAll(startDate, "-", "/") );
        data.append('endDate', Utility.replaceAll(endDate, "-", "/"));



        return this.perform('post', '/saleapp/CountLeadStatusSaleApp' , data, 'multipart/form-data');
    },

    async perform(method, resource, data, headerType) {
        return client({
            method,
            url: resource,
            data: data,
            config: {headers: {'Content-Type': headerType}}

        }).then(resp => {
            return resp.data ? resp.data : [];
        })
            .catch(error => {
                console.log(error);
            });
    }
}
