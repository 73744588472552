<template>
    <v-container fluid class="pl-4 pr-4">
        <v-flex row class="pr-0 pl-0 ml-0 mt-n6">
            <v-col cols=2 class="pl-0">
                <v-select
                        prepend-inner-icon="playlist_add_check"
                        :menu-props="{ maxHeight: '400' }"
                        label="Statuses"
                        multiple
                        item-value="Status"
                        :item-text="GetStatusText"
                        v-model="selectedStatuses"
                        :items="statusCounts"
                        item-color="#4a90e2"
                        v-on:blur="SelectedStatusChanged"
                >

                    <template v-slot:selection="{ item, index }">

                        <v-chip v-if="index === 0">
                            <span>{{ item.Status }}</span>
                        </v-chip>
                        <span
                                v-if="index === 1"
                                class="grey--text caption"
                        >(+{{ selectedStatuses.length - 1 }} others)</span>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="2" class="pl-0">
                <v-dialog
                        ref="dialog"
                        v-model="modalDate"
                        :return-value.sync="dates"
                        persistent
                        width="500px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="dateRangeText" label="Select Dates"
                                prepend-inner-icon="event"
                                readonly
                                v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="dates" width="400px" landscape range full-width>


                    </v-date-picker>
                    <div align="center" style="width:500px;background-color: white;" class="pr-0">
                        <v-btn class="ml-1 mr-1"  style="background-color:#5cb85c; color:white" text  @click="SelectDates('7days')">Last 7 Days</v-btn>
                        <v-btn class="ml-1 mr-1" text style="background-color:#5cb85c; color:white" @click="SelectDates('30days')">Last 30 Days</v-btn>
                        <v-btn class="ml-1 mr-1" text style="background-color:#5cb85c; color:white" @click="SelectDates('month')">Month To Date</v-btn><br/><br/>
                        <v-btn class="ml-1 mr-1" text style="background-color: #82b1ff !important;color:white" @click="modalDate = false">Cancel</v-btn>
                        <v-btn class="ml-1 mr-1" text style="background-color: #82b1ff !important;color:white"  @click="SaveDates('fromOkButtin')">OK</v-btn>
                        <br><br>
                    </div>
                </v-dialog>
            </v-col>
            <v-col cols=5 class="pl-0 pt-6">
                <v-btn @click="OpenAddLead()" style="margin-left: 15px;padding-left: 30px;padding-right: 30px" color="primary" dark>+ Add Lead</v-btn>
                <add-lead :ShowAddLeadDialog.sync="ShowAddLeadDialog"></add-lead>
            </v-col>
            <v-col cols="3" class="text-right pr-0">
                <v-text-field v-model="searchTerm"  label="Search" v-on:keyup.enter="Search" prepend-inner-icon="search"></v-text-field>
            </v-col>
        </v-flex>
        <v-flex class="pl-0 pr-0">
            <table style="width:100%;" id="tasksGridTable">
            <tr>
                <th class="text-left" style="width:130px;padding-left: 20px;"><a v-on:click="SortData('Status')" href="javascript:void(0)">Current Status</a></th>
                <th class="text-left" style="width:150px;"><a v-on:click="SortData('RegisteredDate')" href="javascript:void(0)">Optin</a></th>

                <th class="text-center" style="width:60px;"><a v-on:click="SortData('Trial')" href="javascript:void(0)">Trial</a></th>
                <th class="text-center" style="width:60px;"><a v-on:click="SortData('TrialSent')" href="javascript:void(0)">Done</a></th>
                <th class="text-left" style="width:190px;"><a v-on:click="SortData('Name')" href="javascript:void(0)">Client Name</a></th>
                <th class="text-left" style="width:290px;"><a v-on:click="SortData('Email')" href="javascript:void(0)">Email</a></th>
                <th class="text-left" ><a v-on:click="SortData('Website')" href="javascript:void(0)">Website</a></th>
                <th class="text-left" style="width:280px;"><a v-on:click="SortData('Company')" href="javascript:void(0)">Company</a></th>
                <th class="text-left" style="width:140px;"><a v-on:click="SortData('Phone')" href="javascript:void(0)">Phone</a></th>
                <th style="width:100px;">Details</th>
                </tr>
            <tr v-bind:class="{ oddRow: index & 1, evenRow: !(index & 1) }"  v-for="(c, index) in tasks" v-bind:key="index">
                <td v-bind:class="GetSortClass('Status')" style="padding-left: 20px;" class="text-center"><span v-bind:class="GetStatusColor(c.Status)">{{c.Status}}</span></td>

                <td v-bind:class="GetSortClass('RegisteredDate')" class="text-left" >{{FormatDate(c.RegisteredDate)}}</td>

                <td v-bind:class="GetSortClass('Trial')" class="text-center">{{(c.Trial === false)? "No" : "Yes"}}</td>
                <td v-bind:class="GetSortClass('TrialSent')" class="text-center">{{(c.TrialSent === false)? "-" : "Y"}}</td>

                <td v-bind:class="GetSortClass('Name')" class="text-left" >{{c.Name}}</td>


                <td v-bind:class="GetSortClass('Email')" class="text-left" > <a v-bind:href="'mailto:' + c.Email" title="Open Email Client">{{c.Email}}</a></td>
                <td v-bind:class="GetSortClass('Website')" class="text-left"><a target="_blank" v-bind:href="PrepareSiteUrl(c.Website)">{{PrepareSiteUrl(c.Website)}}</a></td>
                <td v-bind:class="GetSortClass('Company')" class="text-left">{{c.Company}}</td>
                <td v-bind:class="GetSortClass('Phone')" class="text-left">{{(c.Phone.Valid === true)? c.Phone.String : "-"}}</td>
                <td  class="text-center">

                    <v-tooltip left dark>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" style="font-size: 26px"  dark color="black">zoom_in</v-icon>
                        </template>
                        <div class="pl-2 pr-2 pt-4 pb-4">
                            <b>Niche:</b><br/>
                            {{c.Niche}}<br/><br/>
                            <b>Keywords:</b><br/>
                            {{c.Keywords}}<br/><br/>
                            <b>Additional Info:</b><br/>
                            {{c.AdditionalInfo}}<br/>
                        </div>
                    </v-tooltip>
                    <a v-bind:href="PrepareProfileLink(c.RecID)" target="_blank">
                        <v-icon style="font-size: 20px"  dark color="black">edit</v-icon>
                    </a>
                    <a v-on:click="DeleteLead(c.RecID)" v-if="isAdmin" target="_blank">
                        <v-icon style="font-size: 20px"  dark color="black">delete</v-icon>
                    </a>
                </td>
                </tr>


            <tr>
                <td colspan="10">

                    <v-row class="row CssFixer pb-0">
                            <v-col class="col text-left pl-8 pb-0 selectFixer" lg="2" md="2" sm="2">

                                <v-select
                                        v-on:change="Search()"
                                        v-model="take"
                                        id="pagePerRowSelect"
                                        style="width:95px"
                                        :items="itemsPerPage"
                                        label="Rows"
                                        solo
                                ></v-select>
                            </v-col>
                            <v-col class="col text-center pt-5 pb-0" lg="8" md="8" sm="8">

                                <a class="GridPagerLink" v-bind:class="GetCurrentPageIndexClass(index)" v-on:click="GetPage(index)" href="javascript:void(0)" v-for="index in this.PageCount">
                                    {{ index }}
                                </a>

                            </v-col>

                            <v-col class="col text-center pt-4 pb-0" lg="2" md="2" sm="2">
                                <div style="float:right;padding: 10px 5px 0px; font-weight: bold;margin-right: 30px">
                                    {{counts}} items in {{(PageCount === 0)? 1 : PageCount}} pages
                                </div>
                            </v-col>

                            </v-row>



                    </td>
                </tr>
            </table>
            <confirmBox ref="confirm"></confirmBox>
        </v-flex>

    </v-container>
</template>

<script>
    import {MainPageAPIClient} from "../api/HomePageAPI";
    import {Utility} from "../api/utilities";
    import 'material-design-icons-iconfont/dist/material-design-icons.css'
    import confirmBox from "./confirmBox";

    import moment from 'moment'
    import addLead from "./addLead";

    export default {
        name: "alltasks",
        components:{
            confirmBox,
            addLead
        },
        data: function(){
            return{
                ShowAddLeadDialog: false,
                dates: [],
                modalDate: false,
                tasks: [],
                take: 20,
                skip: 0,
                sort: 'RecID',
                sorttype: 'desc',
                searchTerm: '',
                counts : 0,
                currentPageIndex: 1,
                statusCounts: [],
                selectedStatuses: [],
                itemsPerPage: [10, 20, 50, 100],
            }

        },
        watch:{
            'searchTerm':function(val, preVal){
                if(val === '' && preVal !== '')
                    this.Search();
            }
        },
        methods:{
            DeleteLead(recID){
                this.$refs.confirm.open('Delete', 'Are you sure you want to delete this lead?', { color: 'primary' }).then((confirm) => {
                    if(confirm === true){
                        MainPageAPIClient.deleteLead(recID).then(
                            (data) =>{
                                this.Search();
                            }
                        )
                    }
                })
            },
            PrepareProfileLink(recId){
                return 'leadprofile/' + recId;
            },
            OpenAddLead(){
                this.ShowAddLeadDialog = true;
            },
            GetStatusText: item => item.Status + ' - ( ' + item.Count + ' )' ,
            SelectedStatusChanged(){
                this.Search();
            },
            SaveDates(fromOkButton){

                if( fromOkButton === 'fromOkButtin')
                    this.$refs.dialog.save(this.dates);

                this.modalDate = false;
                this.Search();
            },
            SelectDates(range){

                var today = new Date();
                var past = new Date();

                var LastDays = 180;

                if(range == '7days'){
                    past.setDate(today.getDate()- 7);
                }

                if(range == '30days'){
                    past.setDate(today.getDate()- 30);
                }

                if(range == 'month'){
                    var y = today.getFullYear(); var m = today.getMonth();
                    past = new Date(y, m, 1);
                }


                this.dates.splice(0, this.dates.length);

                this.dates.push(past.toISOString().substr(0, 10));
                this.dates.push(today.toISOString().substr(0, 10));


                this.SaveDates();


            },
            FormatDate(dateString){
                return Utility.formatDate2(dateString);
            },
            GetStatusColor(status){
                if(status === 'Optin' || status === 'Emailed')
                    return "Orange";

                if(status === 'Customer' || status === 'Subscriber')
                    return "Green";

                if(status === 'Data' || status === 'Trial')
                    return "Red";

                if(status === 'Locked')
                    return "Blue";

                return "";
            },
            GetSortClass(column){


                if(this.sort == column){
                    return "SortCell";
                }



                return "";
            },
            GetCurrentPageIndexClass(index){
                if(this.currentPageIndex === index)
                    return "CurrentPageIndexClass";

                return "";
            },
            SortData(column){
                if(this.sort == column){
                    this.sorttype = (this.sorttype === 'desc')? 'asc' : 'desc';
                }

                this.sort = column;

                this.GetData(this.take, this.skip, column, this.sorttype, this.searchTerm, this.startDate, this.endDate);
            },
            GetPage(index){
                this.currentPageIndex = index;
                this.GetData(this.take, this.take * (index - 1 ), this.sort, this.sorttype, this.searchTerm, this.startDate, this.endDate);
            },
            PrepareSiteUrl(url){

                if(url === "-")
                    return "-";

                if(url.toLowerCase().startsWith("http") === true)
                  return url;

                return "http://" + url;
            },
            GetPageCount(){

                var arrayStatuses = [];

                this.selectedStatuses.map(function(value, key) {
                    arrayStatuses.push(value);
                });

                MainPageAPIClient.countAllTasksSaleApp(this.searchTerm, this.startDate, this.endDate, arrayStatuses, localStorage.guid).then(
                    (data) =>{
                        if(data.constructor === Array)
                            this.counts = 0;
                        else
                            this.counts = data;
                    }
                )
            },
            Search(){
                this.skip = 0;
                this.GetData(this.take, this.skip, this.sort, this.sorttype, this.searchTerm, this.startDate, this.endDate);
                this.GetPageCount();
            },
            GetData(take, skip, sort, sorttype, search, startDate, endDate){

                var arrayStatuses = [];

                this.selectedStatuses.map(function(value, key) {
                    arrayStatuses.push(value);
                });

                MainPageAPIClient.getAllTasksSaleApp(take, skip, sort, sorttype, search, startDate, endDate, arrayStatuses, localStorage.guid).then(
                    (data) =>{
                        this.tasks = data;
                    }
                );
            },
            GetStatusCounts(refreshAll){
                MainPageAPIClient.countLeadStatusSaleApp(this.startDate, this.endDate).then(
                    (data) =>{

                        this.statusCounts = [];
                        this.selectedStatuses = [];

                        var context = this;
                        data.map(function(value, key) {
                            context.statusCounts.push(value);
                            context.selectedStatuses.push(value.Status);
                        });

                        if(refreshAll === true){
                            this.Search();
                        }


                    }
                );
            }
        },
        computed:{
            isAdmin(){
              if(localStorage.isAdmin === "true"){
                  return true;
              }

              return false;
            },
            dateRangeText () {
                var date0 = this.dates[0] ? moment(this.dates[0]).format('MM-DD-YYYY') : ''
                var date1 = this.dates[1] ? moment(this.dates[1]).format('MM-DD-YYYY') : moment().format('MM-DD-YYYY')

                return date0 + ' ~ ' + date1;
            },
            startDate: function(){
                return this.dateRangeText.split('~')[0].trim();
            },
            endDate: function(){
                return this.dateRangeText.split('~')[1].trim();
            },
            PageCount: function(){
                if(this.counts < this.take)
                    return 0;

                if(this.counts % this.take === 0)
                    Math.round((this.counts / this.take) );

                return Math.floor((this.counts / this.take) + 1 );
            },

        },
        mounted() {

            var today = new Date();
            var past = new Date();
            past.setDate(today.getDate()-180)

            this.dates.push(past.toISOString().substr(0, 10));
            this.dates.push(today.toISOString().substr(0, 10));

            this.GetStatusCounts(true);
        }
    }
</script>

<style scoped>

    .v-picker__body{
        margin: auto;
    }

    .v-picker__title{
        display: none !important;
    }

    .Orange{
        color:#f5a623 !important;
    }

    .Green{
        color: #719844 !important;
    }

    .Red{
        color: #cf513d !important;
    }

    .Blue{
        color: #4a90e2 !important;
    }

    .v-tooltip__content{
        background: rgb(22%, 22%, 22%) !important;
        min-width: 450px;
        max-width: 450px;
        font-size: 16px !important;
    }

    .searchDiv{
        float: right;
        border: solid 0.5px #d6d7d2;
        width:50%;
    }
    .search-input{
        line-height: 25px;
        border: none !important;
        padding: 9px 0px 7px;
        width:90%;
    }

    textarea:focus, input:focus{
        outline: none;
    }

    .CurrentPageIndexClass {
        border-bottom: solid 2px #2983e2 !important;
        color: #2983e2 !important;
        text-decoration: none;
    }

    .GridPagerLink {
        display: inline-block;
        padding: 4px 5px 0px;
        color: #5b5b59;
        margin: 0px 5px;
        font-weight: bold;
    }

    .GridPagerLink:hover, .GridPagerLink:focus {
        text-decoration: none;
    }

    #tasksGridTable {
        border: 1px solid #DBDCD8;
        table-layout: fixed;
        font-size:13px !important;
        border-spacing: 0;
        border-collapse: collapse;
        margin-top: -10px;
    }

    #tasksGridTable th {
        padding: 8px 10px;
        border-top: 1px solid #DBDCD8;
        border-bottom: 1px solid #DBDCD8;
        background-color: #F2F2F2;
        font-size: 15px !important;
        font-weight: normal;
        word-wrap: break-word;
    }

    #tasksGridTable th a, #tasksGridTable th {
        color: #333 !important;
        font-weight: bold;
        text-decoration: none;
        font-size: 15px;
    }

    #tasksGridTable td a{
        text-decoration: none;
    }

    #tasksGridTable .oddRow td {
        padding: 10px 10px;
        background-color: #E6E6E6 !important;
        word-wrap: break-word;
        font-size: 15px;
    }

    #tasksGridTable .evenRow td {
        padding: 10px 10px;
        word-wrap: break-word;
        font-size: 15px;
    }

    #tasksGridTable .SortCell {
        background-color: #F2F2F2;
    }

    .GridPagerLink:hover, .GridPagerLink:focus {
        text-decoration: none;
    }

    .GridPagerLink {
        display: inline-block;
        padding: 4px 5px 0px;
        color: #5b5b59;
        margin: 0px 5px;
        text-decoration: none;
    }



</style>
