import axios from 'axios'

//const BASE_URI = 'http://localhost:57848/';
//const BASE_URI = 'https://devwebapi.steadycontent.com/';
const BASE_URI = 'https://webapi.steadycontent.com/';

const client = axios.create({
    baseURL: BASE_URI,
    json: true
});

export const WebAPIClient = {
    GetSalesPricingLinks: function (param) {
        const data = {}
        return this.perform('get', 'api/sales/GetAllPricingLinks/' + param, data, 'application/json');
    },

    AddSalesPricingLink: function (newsLetterEmailListId, guid, link) {
        const data = {
            Guid: guid,
            Link: link,
            NewsLetterEmailListId: newsLetterEmailListId
        }
        return this.perform('post', 'api/sales/AddSalesPricingLink', data, 'application/json');
    },

    async perform(method, resource, data, headerType) {
        return client({
            method,
            url: resource,
            data: data,
            config: { headers: { 'Content-Type': headerType } }

        }).then(resp => {
            return resp.data ? resp.data : [];
        }).catch(error => {
            console.log('WEBAPI Error',error);
        });
    }
}
