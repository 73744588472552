<template>
    <v-container fluid fill-height class="main-body">
        <v-card class="login-container">
            <v-flex cols="12" class="text-center pt-6 pb-6" >
                <h2>Login</h2>
            </v-flex>
<hr>
            <v-flex cols="12" class="pl-12 pr-12 pt-12 pb-1">
                <v-text-field v-model="email" label="Username"></v-text-field>
            </v-flex>

            <v-flex cols="12" class="pl-12 pr-12 pt-1 pb-12">
                <v-text-field v-model="password" type="password" label="Password"></v-text-field>
            </v-flex>

            <v-flex cols="12" class="pl-12 pr-12 pt-1 pb-12">
                <v-btn @click="Login()" class="loginButton" block color="#659db9" dark>Login</v-btn>
            </v-flex>
        </v-card>
    </v-container>
</template>


<script>

    import {Utility} from "../api/utilities";
    import {LoginAPIClient} from "../api/LoginAPI";

    export default {
        name: "login",

        data: function(){
            return{
                working: false,
                email: '',
                password: '',
        }
        },
        mounted() {
            var g = Utility.getQueryParameter('g')
            if(g !== null && g !== undefined){

                this.working  = true;

                LoginAPIClient.getUserByGuid(g).then(
                    (data) =>{
                        if(data.data.salesPerson === null){
                            alert("Incorrect email and password.")
                        } else{
                            localStorage.fullName = data.data.salesPerson.fullName;
                            localStorage.email = data.data.salesPerson.email;
                            localStorage.guid = data.data.salesPerson.guid;
                            localStorage.isAdmin = data.data.salesPerson.IsAdmin;

                            this.$emit('UserLoggedIn');
                        }

                        this.working = false;

                    }
                )
            }

        },
        methods:{

                 Login(){

                     if(this.email === '' || this.password === ''){
                         alert("Please enter your email and password");
                         return;
                     }

                     if(this.working === true)
                         return;

                     this.working = true;


                     LoginAPIClient.getLoginSaleApp(this.email, this.password).then(
                         (data) =>{
                             if(data.data.salesPerson === null){
                                 console.log(data);
                                alert("Incorrect email and password.")
                             } else{
                                 localStorage.fullName = data.data.salesPerson.fullName;
                                 localStorage.email = data.data.salesPerson.email;
                                 localStorage.guid = data.data.salesPerson.guid;
                                 localStorage.isAdmin = data.data.salesPerson.IsAdmin;



                                 this.$emit('UserLoggedIn');
                             }

                             this.working = false;
                         }
                     );
                 }
            },
    }
</script>

<style scoped>
.main-body{
    background: radial-gradient(ellipse at center, rgba(185,210,224,1) 0%,rgba(187,214,228,1) 0%,rgba(186,211,225,1) 15%,rgba(186,211,225,1) 38%,rgba(169,199,215,1) 68%,rgba(169,199,215,1) 68%,rgba(169,199,215,1) 82%,rgba(158,191,208,1) 100%);
}

    .login-container{
        margin: auto;
        background-color: white;
        border-radius: 5px;
        width: 450px;
        height: 450px;
    }


    button{
        height: 60px !important;

    }

    .v-btn__content{

    }

    h2{
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        color: #9fd1ea;
    }
</style>
