<template>
  <v-expansion-panels id="purchaseLink" v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header>Create Purchase Link</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-flex>
          <v-row class="pt-2 mb-n5 mr-8 ml-8">
            <v-col lg="6" md="12" sm="12" class>
              <v-select
                label="Campaign Name"
                item-value="CampaignID"
                item-text="CampaignName"
                v-model="selectedCampaign"
                :items="campaigns"
                v-on:change="SelectedCampaignChanged"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n6 mr-8 ml-8">
            <v-col lg="2" class="font-weight-bold">
              <div style="padding-top: 6px;">Website:</div>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n2 mr-8 ml-8">
            <v-col lg="8">
              <a :href="'http://' + website" target="_blank">{{ website }}</a>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-1 mr-8 ml-8">
            <v-col lg="6" md="12" sm="12" class>
              <v-text-field label="Location" v-model="location"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n5 mr-8 ml-8">
            <v-col lg="6" md="12" sm="12" class>
              <v-textarea outlined label="Primary Keyword" v-model="keywords"></v-textarea>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n3 mr-8 ml-8">
            <v-col lg="6" md="12" sm="12" class>
              <v-textarea outlined label="Additional Info" v-model="info"></v-textarea>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n3 mr-8 ml-8">
            <v-col lg="6" md="12" sm="12" class>
              <v-textarea outlined label="Purchase Note" v-model="note"></v-textarea>
            </v-col>
          </v-row>

          <v-row class="pt-0 mt-n3 mb-3 mr-8 ml-8">
            <v-col lg="6" md="12" sm="12" class="text-right">
              <v-btn
                color="#7f9bbd"
                dark
                style="padding-left: 30px;padding-right: 30px"
                @click="Save()"
              >{{ SaveText }}</v-btn>
            </v-col>
          </v-row>

          <v-row class="pt-0 mr-8 ml-8">
            <v-col lg="8" md="12" sm="12" class>
              <h2>Content Pricing</h2>
            </v-col>
          </v-row>

          <!-- <v-row class="pt-0 mb-n5 mr-8 ml-8">
            <v-col lg="6">
              <v-select
                v-model="plan"
                :items="plans"
                item-value="value"
                item-text="text"
                id="pagePerRowSelect"
                label="Plan"
              ></v-select>
            </v-col>
          </v-row>-->

          <v-row class="pt-0 mb-n5 mr-8 ml-8">
            <v-col lg="8" sm="12" xs="12">
              <v-select
                v-model="plan"
                @change="PlanChanged"
                :items="plans"
                item-value="value"
                item-text="text"
                id="pagePerRowSelect"
                label="PLAN"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n5 mr-8 ml-8">
            <v-col lg="4" sm="6" xs="12">
              <v-text-field
                type="number"
                @keyup="FrequencyChanged"
                @input="FrequencyChanged"
                v-model="frequency"
                label="HOW MANY / TIMES PER MONTH"
              ></v-text-field>
            </v-col>
            <v-col lg="4" sm="6" xs="12">
              <v-select
                v-model="kwplan"
                :items="kwplans"
                item-value="value"
                item-text="text"
                label="OF KEYWORD RESEARCH / TITLES"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n5 mr-8 ml-8">
            <v-col lg="8" sm="12">
              <v-text-field v-model="words" type="number" label="HOW MANY WORDS"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n5 mr-8 ml-8">
            <v-col lg="4">
              <v-text-field type="number" v-model="pricePerWord" label="Price Per Word (¢)"></v-text-field>
            </v-col>
            <v-col lg="4">
              <v-text-field
                type="number"
                v-model="pricekw"
                label="PRICE PER KEYWORD RESEARCH / TITLE ($)"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n5 mr-8 ml-8">
            <v-col lg="4">
              <v-text-field
                type="number"
                id="boldme1"
                v-model="price"
                label="TOTAL PRICE ($)"
                bold
                disabled
              ></v-text-field>
            </v-col>

            <v-col lg="2">
              <v-text-field
                type="number"
                id="boldme1"
                v-model="priceContent"
                label="CONTENT ($)"
                bold
                disabled
              ></v-text-field>
            </v-col>

            <v-col lg="2">
              <v-text-field
                type="number"
                id="boldme1"
                v-model="priceTitle"
                label="TITLE ($)"
                bold
                disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n5 mr-8 ml-8">
            <v-col lg="4">
              <v-text-field
                type="number"
                v-model="pricechange"
                placeholder=" "
                label="PRICE CHANGE (DOES NOT AFFECT TITLES)"
              ></v-text-field>
            </v-col>

            <v-col lg="4">
              <v-text-field
                type="number"
                id="boldme2"
                v-model="pricechangeppw"
                placeholder=" "
                label="PRICE CHANGE PPW (¢)"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n5 mr-8 ml-8">
            <v-col lg="8">
              <v-text-field
                type="number"
                id="boldme3"
                v-model="pricediscount"
                placeholder=" "
                label="PERCENTAGE (DOES NOT AFFECT TITLES)"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <!--
              <v-row class="pt-0 mb-n5 mr-8 ml-8">
            <v-col lg="6">
              <v-text-field type="number" v-model="pricePerWord" label="Price Per Word (¢)"></v-text-field>
            </v-col>
          </v-row>
              
               <v-row class="pt-0 mb-n5 mr-8 ml-8">
            <v-col lg="6">
              <v-text-field type="number" v-model="frequency" label="Frequency"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n5 mr-8 ml-8">
            <v-col lg="6">
              <v-text-field v-model="words" type="number" label="Words"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n5 mr-8 ml-8">
            <v-col lg="6">
              <v-text-field v-model="kw" type="number" label="KW Research / Titles"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n5 mr-8 ml-8">
            <v-col lg="6">
              <v-text-field type="number" v-model="price" label="($)Price"></v-text-field>
            </v-col>
          </v-row>-->

          <v-row class="pt-0 mb-n6 mr-8 ml-8" v-show="ShowPackageLink">
            <v-col lg="2" class="font-weight-bold">
              <div style="padding-top: 6px;">Package Link:</div>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n3 mr-8 ml-8" v-show="ShowPackageLink">
            <v-col lg="12">
              <a :href="Link" target="_blank">{{Link}}</a>

              <v-tooltip v-model="show" top>
                <template v-slot:activator="{ on }">
                  <v-btn icon @click="Copy()" v-on="on">
                    <v-icon small>file_copy</v-icon>
                  </v-btn>
                </template>
                <span>Copy To Clipboard</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-n6 mr-0 ml-8">
            <v-col lg="2" class="font-weight-bold">
              <div style="padding-top: 6px;">Customer Link:</div>
            </v-col>
          </v-row>

          <v-row class="pt-0 mb-3 mr-0 ml-8">
            <v-col lg="12">
              <a :href="CustomerLink" target="_blank">{{ CustomerLink }}</a>

              <v-tooltip v-model="show" top>
                <template v-slot:activator="{ on }">
                  <v-btn icon @click="CopyCustomerLink()" v-on="on">
                    <v-icon small>file_copy</v-icon>
                  </v-btn>
                </template>
                <span>Copy To Clipboard</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row class="pt-0 mr-8 ml-8">
            <v-col sm="12" class>
              <v-data-table
                :headers="headersPricingLinks"
                :items="pricingLinksData"
                :search="search"                
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>Pricing Links</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field
                      class="text-xs-center"
                      v-model="search"
                      append-icon="search"
                      label="Search"
                      single-line
                      hide-details
                    />
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-flex>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { LeadProfilePageAPIClient } from "../api/LeadProfilePageAPI";
import { EventBus } from "../api/bus.js";
import { Utility } from "../api/utilities";
import { WebAPIClient } from "../api/WebApi";

export default {
  name: "purchaseLink",
  data: function() {
    return {
      panel: 0,
      campaign: "",
      frequency: 4,
      words: 800,
      company: "",
      website: "",
      plan: "",
      location: "",
      keywords: "",
      info: "",
      plans: [],
      plan: "m",
      campaigns: [],
      selectedCampaign: "",
      price: 0,
      show: false,
      guid: "",
      pricePerWord: 12.375,
      note: "",
      working: false,
      customerId: -1,
      //kw: 0,
      leadId: -1,
      ShowPackageLink: false,
      kwplans: [],
      kwplan: 8,
      pricekw: 5,
      pricechange: "",
      pricechangeppw: "",
      pricediscount: "",
      //princing links
      search: "",
      pricingLinksData: [],
      headersPricingLinks: [
        { text: "User Name", value: "Guid", width: 130 },
        { text: "Date", value: "DisplayDate", width: 115 },
        { text: "Link", value: "Link", sortable: false }
      ]
    };
  },
  watch: {
    pricekw: function(val, preVal) {
      this.CalculatePrice();
      this.CalculateDicountPrice();
    },
    kwplan: function(val, preVal) {
      this.CalculatePrice();
      this.CalculateDicountPrice();
    },
    pricePerWord: function(val, preVal) {
      this.CalculatePrice();
      this.CalculateDicountPrice();
    },
    plan: function(val, preVal) {
      this.CalculatePrice();
      this.CalculateDicountPrice();
    },
    words: function(val, preVal) {
      this.CalculatePrice();
      this.CalculateDicountPrice();
    },
    frequency: function(val, preVal) {
      //this.kw = val * 2;
      this.CalculatePrice();
      this.CalculateDicountPrice();
    },
    pricechange: function(val, preVal) {
      this.CalculateDicountPrice();
    }
  },
  created() {
    this.bindPricingLinks();
    this.CalculatePrice();
  },
  methods: {
    bindPricingLinks() {
      let param = this.$route.params.id;
      WebAPIClient.GetSalesPricingLinks(param).then(data => {       
        this.pricingLinksData = data;
      });
    },
    addPricingLink(link) {
      WebAPIClient.AddSalesPricingLink(
        this.$route.params.id,
        localStorage.guid,
        link
      ).then(data => {
        this.bindPricingLinks();
      });
    },
    FrequencyChanged() {
      if (this.plan === "o") {
        this.kwplan = this.frequency * 1;
      } else {
        this.kwplan = this.frequency * 2;
      }
    },
    PlanChanged() {
      if (this.plan === "o") {
        this.kwplan = this.frequency * 1;
      } else {
        this.kwplan = this.frequency * 2;
      }
    },

    CalculatePrice() {
      var myPrice = this.words * this.frequency * (this.pricePerWord / 100);

      if (this.plan === "a") myPrice = myPrice * 12;

      if (this.plan === "o") myPrice += this.kwplan * this.pricekw;

      this.price = Math.round(myPrice * 100) / 100;
    },

    CalculateDicountPrice() {
      if (this.pricechange === "") {
        this.pricechangeppw = "";
        this.pricediscount = "";
        return;
      }

      var myPrice = this.pricechange;

      if (this.plan === "o") myPrice -= this.kwplan * this.pricekw;

      this.pricediscount = 100 - (this.pricechange * 100) / this.price;
      this.pricediscount = Math.round(this.pricediscount * 100) / 100;

      myPrice = this.pricechange;

      if (this.plan === "o") myPrice -= this.kwplan * this.pricekw;
      if (this.plan === "a") myPrice = myPrice / 12;

      this.pricechangeppw = (myPrice / this.words / this.frequency) * 100;
      this.pricechangeppw = Math.round(this.pricechangeppw * 1000) / 1000;
    },

    Copy() {
      Utility.copyToClipboard(this.Link);
      this.addPricingLink(this.Link);
    },
    CopyCustomerLink() {
      Utility.copyToClipboard(this.CustomerLink);
      this.addPricingLink(this.CustomerLink);
    },
    SelectedCampaignChanged() {
      var context = this;
      this.campaigns.map(function(value, key) {
        if (value.CampaignID === context.selectedCampaign) {
          context.frequency = value.Frequency;
          context.words = value.WordCount;
          context.keywords = value.PrimaryKeywords;
          context.info = value.ContentGoal;
          context.location = value.GeoLocation;
          context.website = value.BlogUrl;
          context.guid = value.Guid;
          context.note = value.PurchaseNotes;
          context.CampaignID = value.CampaignID;
        }
      });
    },
    Save() {
      this.working = true;
      LeadProfilePageAPIClient.saveCampaign(
        this.selectedCampaign,
        this.info,
        this.keywords,
        this.note
      ).then(data => {
        this.working = false;
        var context = this;
        var index = 0;
        this.campaigns.map(function(value, key) {
          if (value.CampaignID === context.selectedCampaign) {
            context.campaigns[index].ContentGoal = context.info;
            context.campaigns[index].PurchaseNotes = context.note;
            context.campaigns[index].PrimaryKeywords = context.keywords;
          }

          index++;
        });
      });
    }
  },
  computed: {
    priceContent() {
      var myPrice = this.frequency * this.words * this.pricePerWord;

      if (this.plan === "a") myPrice *= 12;

      myPrice = myPrice / 100;

      return Math.round(myPrice * 100) / 100;
    },
    priceTitle() {
      if (this.plan != "o") return 0;

      return this.pricekw * this.kwplan;
    },
    Link() {
      var ppw = this.pricePerWord;
      if (this.pricechangeppw !== "") ppw = this.pricechangeppw;

      var link =
        "https://access.steadycontent.com/vip?sid=" +
        localStorage.guid +
        "&ppw=" +
        ppw +
        "&w=" +
        this.words +
        "&f=" +
        this.frequency +
        "&pr=" +
        this.price +
        "&pl=" +
        this.plan +
        "&kw=" +
        this.kwplan +
        "&g=" +
        this.guid;

      if (this.plan === "o" && this.kwplan > 0) link += "&kwp=" + this.pricekw;

      return link;
    },
    CustomerLink() {
      var ppw = this.pricePerWord;
      if (this.pricechangeppw !== "") ppw = this.pricechangeppw;

      var link =
        "https://access.steadycontent.com/vip?sid=" +
        localStorage.guid +
        "&ppw=" +
        ppw +
        "&w=" +
        this.words +
        "&f=" +
        this.frequency +
        "&pr=" +
        this.price +
        "&pl=" +
        this.plan +
        "&cid=" +
        this.customerId +
        "&kw=" +
        this.kwplan +
        "&lid=" +
        this.leadId;

      if (this.plan === "o" && this.kwplan > 0) link += "&kwp=" + this.pricekw;

      return link;
    },
    SaveText() {
      if (this.working === true) return "Working...";

      return "Save";
    }
  },
  mounted() {
    this.plans.push({ text: "Monthly", value: "m" });
    this.plans.push({ text: "One time order (OTO)", value: "o" });
    this.plans.push({ text: "Annual", value: "a" });

    this.kwplans.push({ text: "0 - No KW Research or Titles", value: 0 });

    var i;
    for (i = 1; i < 60; i++) {
      this.kwplans.push({
        text: i.toString() + " - KW Research or Titles",
        value: i
      });
    }

    LeadProfilePageAPIClient.getLeadCustomerId(this.$route.params.id).then(
      data => {
        this.leadId = data.data.lead.recID;
        if (data.data.lead.CustomerId !== null) {
          EventBus.$emit("customerId-captured", data.data.lead.CustomerId);
          this.customerId = data.data.lead.CustomerId;

          LeadProfilePageAPIClient.getCampaigns(data.data.lead.CustomerId).then(
            data1 => {
              this.campaigns = data1.data.campaigns;
              if (this.campaigns.length > 0) {
                this.selectedCampaign = this.campaigns[0].CampaignID;
                //this.frequency = this.campaigns[0].Frequency;
                //this.words = this.campaigns[0].WordCount;
                this.keywords = this.campaigns[0].PrimaryKeywords;
                this.info = this.campaigns[0].ContentGoal;
                this.location = this.campaigns[0].GeoLocation;
                this.website = this.campaigns[0].BlogUrl;
                this.guid = this.campaigns[0].Guid;
                this.note = this.campaigns[0].PurchaseNotes;

                //this.kwplan = this.frequency * 4;

                this.ShowPackageLink = true;
              } else {
                this.ShowPackageLink = false;
              }
            }
          );
        }
      }
    );
  }
};
</script>

<style scoped>
.v-expansion-panel-header {
  min-height: 64px;
  background-color: #f3f2f3 !important;
  font-size: 18px !important;
  font-weight: bold;
}

.v-btn {
  text-transform: none !important;
}
</style>
