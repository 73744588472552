<template>
    <v-expansion-panels id="wordsSubscriptionLink" v-model="panel">
        <v-expansion-panel >
            <v-expansion-panel-header>Volume Discount Link</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-flex>


                    <v-row class="pt-0 mb-n5 mr-8 ml-8 mt-4">
                        <v-col lg="6">
                            <v-text-field type="number" v-model="pricePerWord"  label="Price Per Word (¢)"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="pt-0 mb-n5 mr-8 ml-8">
                        <v-col lg="6">
                            <v-text-field type="number" v-model="words"  label="Words Per Month"></v-text-field>
                        </v-col>
                    </v-row>



                    <v-row class="pt-0 mb-n5 mr-8 ml-8">
                        <v-col lg="6">
                            <v-text-field type="number" v-model="price" label="($)Total"></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row class="pt-0 pr-0 mb-n6 mr-8 ml-8">
                        <v-col lg="6" class="font-weight-bold">
                            <div style="padding-top: 6px;">Volume Discount Link:</div>
                        </v-col>
                    </v-row>
                    <v-row class="pt-0 mr-8 ml-8 mb-0">
                        <v-col lg="12" class="pr-0">
                            <a :href="Link" target="_blank">{{ Link }}</a>


                            <v-tooltip v-model="show" top>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon @click="Copy()" v-on="on">
                                        <v-icon small>file_copy</v-icon>
                                    </v-btn>
                                </template>
                                <span>Copy To Clipboard</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>



                </v-flex>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import {LeadProfilePageAPIClient} from '../api/LeadProfilePageAPI';
    import { EventBus } from '../api/bus.js';
    import {Utility} from "../api/utilities";

    export default {
        name: "purchaseLink",
        data:function () {
            return{
                panel : 0,
                price: 599,
                pricePerWord: 6,
                words : 10000,
                show: false,
                guid: ''
            }
        },
        watch:{

            'pricePerWord': function(val, preVal){
                this.CalculatePrice();
            },
            'words': function(val, preVal){
                this.CalculatePrice();
            }
        },
        methods:{
            CalculatePrice(){

                var myPrice = this.words  * (this.pricePerWord / 100);

                this.price = Math.round(myPrice * 100) / 100;
            },
            Copy(){
                Utility.copyToClipboard(this.Link);
            },



        },
        computed:{
            Link(){
                var link = "https://app.steadycontent.com/Sales/OrderWords.aspx?w=" + this.words + "&tp=" + this.price + "&ppw=" + this.pricePerWord + "&g=" + this.guid;

                return link;
            },


        },
        created (){
            EventBus.$on('customerId-captured', (data) => {
                LeadProfilePageAPIClient.getCustomer(data).then(
                    (data)=>{
                        this.guid = data.data.customer.guid;
                        this.$forceUpdate();
                    }
                )
            })
        },
    }
</script>

<style scoped>
    .v-expansion-panel-header {
        min-height: 64px;
        background-color: #F3F2F3 !important;
        font-size: 18px !important;
        font-weight: bold;
    }

    .v-btn{
        text-transform: none !important;
    }
</style>
