<template>
    <v-container fluid class="colorBack" v-scroll="onScroll" id="myScroller">
        <v-container class="pt-10">

        <v-row>
            <v-col lg="2" xl="2" class="hidden-md-and-down">
                <div id="menu">
                    <div @click="$vuetify.goTo('#leadInfo', options)"><span><v-icon class="iconHolder" style="font-size: 20px"  v-bind:color="GetLinkColorLeadInfo()">settings_application</v-icon></span><span v-bind:style="{ color: GetLinkColorLeadInfo() }">Client Info</span></div>
                    <div @click="$vuetify.goTo('#purchaseLink', options)"><span><v-icon class="iconHolder" style="font-size: 20px"   v-bind:color="GetLinkColorPurchase()">build</v-icon></span><span v-bind:style="{ color: GetLinkColorPurchase() }">Purchase Link</span></div>
                    <div @click="$vuetify.goTo('#wordsSubscriptionLink', options)"><span><v-icon class="iconHolder" style="font-size: 20px"   v-bind:color="GetLinkColorWords()">view_module</v-icon></span><span v-bind:style="{ color: GetLinkColorWords() }">Volume Discount Link</span></div>
                </div>

            </v-col>
            <v-col lg="10" xl="10" md="12">
                <leadInfo></leadInfo>
                <br/>
                <purchase-link></purchase-link>
                <br/>
                <words-link></words-link>
            </v-col>
        </v-row>

        </v-container>
    </v-container>

</template>

<script>

    import leadInfo from "../components/leadInfo";
    import purchaseLink from "../components/purchaseLink";
    import wordsLink from "../components/wordsSubscriptionLink";
    import { Scroll } from 'vuetify/lib/directives';

    export default {
        name: "leadprofile",
        computed: {
            options() {
                return {
                    offset: 50,
                    duration: 800
                }
            },
        },
        data: () => ({
            isLeadInfoBlue: true,
            isPurchaseBlue: false,
            isWordsBlue: false
        }),
        methods:{
            GetLinkColorLeadInfo(){
                if(this.isLeadInfoBlue === true) return "#1976d2";

                return "#2b2c50"
            },
            GetLinkColorPurchase(){
                if(this.isPurchaseBlue === true) return "#1976d2";

                return "#2b2c50"
            },
            GetLinkColorWords(){
                if(this.isWordsBlue === true) return "#1976d2";

                return "#2b2c50"
            },
            onScroll (e) {
                if(window.scrollY < document.getElementById("purchaseLink").offsetTop - 300){
                    this.isLeadInfoBlue = true;
                    this.isPurchaseBlue = false;
                    this.isWordsBlue = false;
                }
                else if(window.scrollY > document.getElementById("purchaseLink").offsetTop  && window.scrollY < document.getElementById("wordsSubscriptionLink").offsetTop){

                    this.isLeadInfoBlue = false;
                    this.isPurchaseBlue = false;
                    this.isWordsBlue = true;
                }
                else{

                    this.isLeadInfoBlue = false;
                    this.isPurchaseBlue = true;
                    this.isWordsBlue = false;


                }

            },
        },
        components: {
            leadInfo,
            purchaseLink,
            wordsLink
        }
    }
</script>

<style scoped>
    #menu{
        position: fixed;
        top: 125px;
        margin-top: 15px;
    }



    #menu div{
        margin-bottom: 15px;
    }

    #menu div:hover{
        cursor: pointer;
    }

    .iconHolder{
        width:30px;
        display: inline-block;
    }

    .colorBack{
        background-color:  rgb(98%, 98%, 98%) !important;
    }

    @media (min-width: 1200px) {
        .container {
            max-width: 1450px !important;
        }
    }



</style>
