import axios from 'axios'
import {Utility} from "../api/utilities";
//const BASE_URI1 = 'http://localhost:8081/';
const BASE_URI1 = 'https://goapi.steadycontent.com/';
//const BASE_URI1 = 'http://devgoapi.steadycontent.com/';

const client1 = axios.create({
    baseURL: BASE_URI1,
    json: true
});

export const LoginAPIClient = {

    getLoginSaleApp: function (email, password) {
        const data = new FormData();
        data.append('query', "{salesPerson(email:\"" + email + "\",password:\"" + password +"\"){guid, email, fullName,  firstName, lastName, IsAdmin}}");


        return this.perform('post', '/graphql' , data, 'multipart/form-data');
    },

    getStripeId: function (email) {
        const data = new FormData();

        data.append('query', "{salesPerson(email:\"" + email +"\"){StripeID, guid}}");


        return this.perform('post', '/graphql' , data, 'multipart/form-data');
    },

    getUserByGuid: function (guid) {
        const data = new FormData();

        data.append('query', "{salesPerson(guid:\"" + guid +"\"){guid, email, fullName,  firstName, lastName, IsAdmin}}");


        return this.perform('post', '/graphql' , data, 'multipart/form-data');
    },



    async perform(method, resource, data, headerType) {
        return client1({
            method,
            url: resource,
            data: data,
            config: {headers: {'Content-Type': headerType}}

        }).then(resp => {
            return resp.data ? resp.data : [];
        })
            .catch(error => {
                console.log(error);
            });
    }
}
