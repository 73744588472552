import axios from 'axios'
import {Utility} from "../api/utilities";
//const BASE_URI2 = 'http://localhost:8081/';
const BASE_URI2 = 'https://goapi.steadycontent.com/';
//const BASE_URI2 = 'http://devgoapi.steadycontent.com/';

const client2 = axios.create({
    baseURL: BASE_URI2,
    json: true
});

export const LeadProfilePageAPIClient = {


    getCampaigns: function (recId) {
        const data = new FormData();
        data.append('query', "{campaigns(customerId:" + recId  + ", salePoint: \"AppContent\"){CampaignID, CampaignName, WordCount, Frequency, HasBulkOrder, BlogUrl, GeoLocation, ContentGoal, PrimaryKeywords, Status, Guid, PurchaseNotes, KWTitles}}");


        return this.perform('post', '/graphql' , data, 'multipart/form-data');
    },

    getCustomer: function (recId) {
        const data = new FormData();
        data.append('query', "{customer(recID:" + recId  + "){emailAddress, guid}}");


        return this.perform('post', '/graphql' , data, 'multipart/form-data');
    },

    getLeadCustomerId: function (recId) {
        const data = new FormData();
        data.append('query', "{lead(id:" + recId  + "){CustomerId, recID}}");


        return this.perform('post', '/graphql' , data, 'multipart/form-data');
    },

    getCampaignsByCustomerId: function (recId) {
        const data = new FormData();
        data.append('query', "{lead(id:" + recId  + "){CustomerId}}");


        return this.perform('post', '/graphql' , data, 'multipart/form-data');
    },

    getLead: function (recId) {
        const data = new FormData();
        data.append('query', "{lead(id:" + recId  + "){email, name, website, company, niche, additionalInfo, keywords, objection, phone, status, funnel, Gclid, SalesPersonId}}");


        return this.perform('post', '/graphql' , data, 'multipart/form-data');
    },

    saveLead: function (recId,email, name, website, company, niche, additionalInfo, keywords, objection, phone ) {
        const data = new FormData();

        additionalInfo = Utility.replaceAll("\"\"" + additionalInfo + "\"\"", "\\", "/")
        keywords = Utility.replaceAll("\"\"" + keywords + "\"\"", "\\", "/")
        objection = Utility.replaceAll("\"\"" + objection + "\"\"", "\\", "/")

        data.append('query', "mutation M { newLead: saveLead(email: \"" + email +"\", name: \""+ name +"\", company: \"" + company + "\", id: " + recId +", website: \"" + website + "\", info : \"" + additionalInfo + "\", niche: \"" +niche  + "\", keywords: \"" + keywords+ "\", objection: \"" +objection+ "\" , phone: \"" +phone+ "\") }");


        return this.perform('post', '/graphql' , data, 'multipart/form-data');
    },

    saveCampaign: function (recId, additionalInfo, keywords, note ) {
        const data = new FormData();

        additionalInfo = Utility.replaceAll("\"\"" + additionalInfo + "\"\"", "\\", "/")
        keywords = Utility.replaceAll("\"\"" + keywords + "\"\"", "\\", "/")
        note = Utility.replaceAll("\"\"" + note + "\"\"", "\\", "/")


        data.append('query', "mutation M { newCampaign: saveCampaign(note: \"" + note +"\", info: \""+ additionalInfo +"\", keywords: \"" + keywords + "\", id: " + recId +") }");


        return this.perform('post', '/graphql' , data, 'multipart/form-data');
    },

    async perform(method, resource, data, headerType) {
        return client2({
            method,
            url: resource,
            data: data,
            config: {headers: {'Content-Type': headerType}}

        }).then(resp => {
            return resp.data ? resp.data : [];
        })
            .catch(error => {
                console.log(error);
            });
    }
}
