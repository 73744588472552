<template>
    <v-expansion-panels id="leadInfo" v-model="panel">
        <v-expansion-panel >
            <v-expansion-panel-header>Client Information</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-flex>
                   <v-row class="pt-2 mb-n5 mr-8 ml-8">
                       <v-col lg="6" md="12" sm="12" class="pr-6">
                           <v-text-field label="Company Name" v-model="company" ></v-text-field>
                       </v-col>
                        <v-col lg="6" md="12" sm="12" class="pl-6">
                            <v-text-field label="Website URL" v-model="website" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="pt-0 mb-n5 mr-8 ml-8">
                        <v-col lg="6" md="12" sm="12" class="pr-6">
                            <v-text-field label="Name" v-model="name" ></v-text-field>
                        </v-col>

                        <v-col lg="6" md="12" sm="12" class="pl-6">
                            <v-text-field label="Email"  v-model="email" ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row class="pt-0 mb-n5 mr-8 ml-8">
                        <v-col lg="6" md="12" sm="12" class="pr-6">
                            <v-text-field label="Phone Number" v-model="phone" ></v-text-field>
                        </v-col>
                        <v-col lg="6" md="12" sm="12" class="pl-6">
                            <v-text-field label="Niche"  v-model="niche" ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="pt-0 mr-8 ml-8">
                        <v-col lg="6" md="12" sm="12" class="pr-6">
                            <v-text-field readonly filled label="Status" v-model="status" ></v-text-field>
                        </v-col>

                        <v-col lg="6" md="12" sm="12" class="pr-6">
                            <v-text-field readonly filled label="Source" v-model="source" ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row class="pt-0 mb-n5 mr-8 ml-8">
                        <v-col lg="12" md="12" sm="12" class="">
                            <v-textarea outlined v-model="keywords" label="Keywords (Comma Separated )">
                            </v-textarea>
                        </v-col>
                    </v-row>

                    <v-row class="pt-0 mb-n5 mr-8 ml-8">
                        <v-col lg="12" md="12" sm="12" class="">
                            <v-textarea outlined label="Additional Info" v-model="info" ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row class="pt-0 mb-n5 mr-8 ml-8">
                        <v-col lg="12" md="12" sm="12">
                            <v-textarea outlined label="Note/Objection" v-model="objection" ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row class="pt-0 mb-n6 mr-8 ml-8">
                        <v-col lg="2" class="font-weight-bold">
                            <div style="padding-top: 6px;">Continuation Link:</div>
                        </v-col>
                    </v-row>
                    <v-row class="pt-0 mb-2 mr-8 ml-8">

                        <v-col lg="12" md="10" sm="10" class="">
                            <a :href="ContinuationLink" target="_blank">{{ ContinuationLink }}</a>

                            <v-tooltip v-model="show" top>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon @click="Copy()" v-on="on">
                                        <v-icon small>file_copy</v-icon>
                                    </v-btn>
                                    </v-btn>
                                </template>
                                <span>Copy To Clipboard</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>



                    <v-row class="pt-0 mb-4 mr-8 ml-8 text-right">
                        <v-col lg="12" md="12" sm="12">
                            <v-btn color="#7f9bbd" dark style="padding-left: 30px;padding-right: 30px" @click="Save()">{{ SaveText }}</v-btn>
                        </v-col>
                    </v-row>



                </v-flex>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>

    import {LeadProfilePageAPIClient} from '../api/LeadProfilePageAPI';
    import {Utility} from "../api/utilities";
    import { EventBus } from '../api/bus.js';

    export default {
        name: "leadInfo",
        created (){
            EventBus.$on('customerId-captured', (data) => {
                LeadProfilePageAPIClient.getCustomer(data).then(
                    (data)=>{
                        this.customerEmail = data.data.customer.emailAddress;
                        this.guid = data.data.customer.guid;
                        this.$forceUpdate();
                    }
                )
            })
        },
        mounted(){

            LeadProfilePageAPIClient.getLead(this.$route.params.id).then(
                (data)=> {
                    this.company = data.data.lead.company;
                    this.niche = data.data.lead.niche;
                    this.email = data.data.lead.email;
                    this.info = data.data.lead.additionalInfo;
                    this.keywords = data.data.lead.keywords;
                    this.objection = data.data.lead.objection;
                    this.phone = Utility.prepareGoNullableFields(data.data.lead.phone);
                    this.website = data.data.lead.website;
                    this.status = data.data.lead.status;
                    this.name = data.data.lead.name;
                    this.funnel = data.data.lead.funnel;
                    this.gclid = data.data.lead.Gclid;
                    this.SalesPersonId = data.data.lead.SalesPersonId;


                }
            )
            },
        methods:{
            Copy(){
                Utility.copyToClipboard(this.ContinuationLink);
            },
            Save(){
                this.working = true;
                LeadProfilePageAPIClient.saveLead(this.$route.params.id, this.email, this.name, this.website, this.company, this.niche, this.info, this.keywords, this.objection, this.phone).then(
                    (data)=>{
                        this.working = false;
                    }
                )
            }
        },
        computed:{
            source(){
                if(this.gclid !== '' && this.gclid !== undefined && this.gclid !== null)
                    return "AdWord";


                if(this.SalesPersonId > 0)
                    return "Affiliate"

                return "-";
            },
            SaveText(){
                if(this.working === true)
                    return "Working..."

                return "Save";
            },
            ContinuationLink(){
                if(this.funnel == 'TrialFunnelCC'){
                    return "https://try.steadycontent.com/content-details?e=" + this.customerEmail + "&g=" + this.guid;
                }

                return "https://trial.steadycontent.com/content-details"
            }

        },
        data:function () {
            return{
                panel : 0,
                company: '',
                name: '',
                email: '',
                phone: '',
                website: '',
                niche: '',
                keywords: '',
                info:'',
                objection: '',
                status: '',
                funnel: '',
                working: false,
                guid: '',
                customerEmail : '',
                customerId:'',
                show: false,
                gclid : '',
                SalesPersonId: -1
            }
        }
    }
</script>

<style scoped>

    .v-expansion-panel-header {
        min-height: 64px;
        background-color: #F3F2F3 !important;
        font-size: 18px !important;
        font-weight: bold;
    }


    .v-btn{
        text-transform: none !important;
    }
</style>
