<template>
    <div class="pt-6 pl-4 pr-4 border-container mt-5 ml-4 mr-4">


        <v-tabs  color="#4a4a4a" background-color="transparent" :grow="false">
            <v-tab class="no-transition">
                Prospects
            </v-tab>
            <v-tab-item class="no-transition">
                <alltasks></alltasks>
            </v-tab-item>
            <v-tab >
                Payments
            </v-tab>
            <v-tab-item class="no-transition">
                <div style="margin-left: 18px">Under Construction</div>
            </v-tab-item>
            <v-tab class="no-transition">
                Profile
            </v-tab>
            <v-tab-item class="no-transition">
                <profile></profile>
            </v-tab-item>
            <v-tab >
                Link Builder
            </v-tab>
            <v-tab-item class="no-transition">
               <link-builder></link-builder>
            </v-tab-item>

        </v-tabs>
    </div>

</template>

<script>
    import alltasks from "./alltasks";
    import LinkBuilder from "./linkBuilder";
    import Profile from "./profile";
    import {Utility} from "../api/utilities";
    import {LoginAPIClient} from "../api/LoginAPI";

    export default {
        name: "tabs",
        data: () => ({
            email: '',
            fullName: ''
        }),
        mounted(){




            if(localStorage.email === ''){
                this.$router.push('/login');
            } else{
                this.email = localStorage.email;
                this.fullName = localStorage.fullName;
            }


        },
        watch:{
            'email':function(val, preVal){ if(this.email === '') this.$router.push('/login');},
        },
        components:{
            Profile,
            LinkBuilder,
            alltasks
        }
    }
</script>

<style scoped>
    .v-content__wrap{
        background-color:  white!important;
    }


    .v-tab{
        border-bottom: none !important;
    }

</style>
