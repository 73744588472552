<template>
    <div class="ml-4 pb-12 mt-6">
        <div>
            <v-flex>
                <v-row>
                    <v-col lg="5" class="mt-n6 mr-0 pr-8">

                        <v-row class="mb-2">
                            <v-col>
                                <h3>Create Purchase Link</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="12">
                                <v-text-field type="name" v-model="name" placeholder=" "  label="FULL NAME (NAME ON CARD)"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="12">
                                <v-text-field type="email" v-model="email" placeholder=" "  label="EMAIL ADDRESS"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="12">
                                <v-select v-model="plan" @change="PlanChanged" :items="plans" item-value="value" item-text="text" id="pagePerRowSelect" label="PLAN"></v-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col lg="6">
                                <v-text-field type="number" @keyup="FrequencyChanged" @input="FrequencyChanged" v-model="frequency" label="HOW MANY / TIMES PER MONTH"></v-text-field>
                            </v-col>
                            <v-col lg="6">
                                <v-select v-model="kwplan"  :items="kwplans" item-value="value" item-text="text" label="OF KEYWORD RESEARCH / TITLES"></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="12">
                                <v-text-field v-model="words" type="number"  label="HOW MANY WORDS"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="6">
                                <v-text-field type="number" v-model="pricePerWord"  label="Price Per Word (¢)"></v-text-field>
                            </v-col>

                            <v-col lg="6">
                                <v-text-field type="number" v-model="pricekw"  label="PRICE PER KEYWORD RESEARCH / TITLE ($)"></v-text-field>
                            </v-col>
                        </v-row>



                        <v-row>
                            <v-col lg="6">
                                <v-text-field type="number" id="boldme1" v-model="price" label="TOTAL PRICE ($)" bold disabled></v-text-field>
                            </v-col>

                            <v-col lg="3">
                                <v-text-field type="number" id="boldme1" v-model="priceContent" label="CONTENT ($)" bold disabled></v-text-field>
                            </v-col>

                            <v-col lg="3">
                                <v-text-field type="number" id="boldme1" v-model="priceTitle" label="TITLE ($)" bold disabled></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col lg="6">
                                <v-text-field type="number" v-model="pricechange" placeholder=" "  label="PRICE CHANGE (DOES NOT AFFECT TITLES)"></v-text-field>
                            </v-col>

                            <v-col lg="6">
                                <v-text-field type="number" id="boldme2" v-model="pricechangeppw" placeholder=" "  label="PRICE CHANGE PPW (¢)" disabled></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col lg="12">
                                <v-text-field type="number" id="boldme3"  v-model="pricediscount" placeholder=" " label="PERCENTAGE (DOES NOT AFFECT TITLES)" disabled></v-text-field>
                            </v-col>
                        </v-row>


                    </v-col>


                    <v-col lg="7" class="mt-n6">
                        <v-row>
                            <v-col>
                                <h3>Links</h3>
                            </v-col>
                        </v-row>

                        <v-row class="pt-3">
                            <v-col lg="12">
                                <div style="margin-bottom: -3px;color: rgba(0, 0, 0, 0.54);">TRIAL</div>
                                <a :href="LinkTrial" target="_blank">{{ LinkTrial }}</a>

                                <v-tooltip v-model="showTrial" top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon @click="CopyTrial()" v-on="on">
                                            <v-icon small>file_copy</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Copy To Clipboard</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>

                        <v-row class="pt-3">
                            <v-col lg="12">
                                <div style="margin-bottom: -3px;color: rgba(0, 0, 0, 0.54);">CONFIGURATION</div>
                                <a :href="Link" target="_blank">{{ Link }}</a>


                                <v-tooltip v-model="show" top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon @click="Copy()" v-on="on">
                                            <v-icon small>file_copy</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Copy To Clipboard</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-flex>
        </div>


    </div>


</template>

<script>

    import {Utility} from "../api/utilities";

    export default {
        name: "linkBuilder",
        data: () => ({
            words: 800,
            name: '',
            email: '',
            frequency: 4,
            price: 396,
            plans: [],
            plan: 'm',
            show: false,
            showTrial: false,
            pricePerWord: 12.375,
            kwplans: [],
            kwplan: 8,
            pricekw: 5,
            pricechange: '' ,
            pricechangeppw: '' ,
            pricediscount: '' ,
        }),
        methods:{
            FrequencyChanged(){
                if(this.plan === "o"){
                    this.kwplan = this.frequency * 1
                }else{
                    this.kwplan = this.frequency * 2
                }
            },
            PlanChanged(){
              if(this.plan === "o"){
                  this.kwplan =  this.frequency * 1
              }else{
                  this.kwplan = this.frequency * 2
              }
            },
            Copy(){
                Utility.copyToClipboard(this.Link);
            },
            CopyTrial(){
                Utility.copyToClipboard(this.LinkTrial);
            },
            CalculatePrice(){
                var myPrice = this.words * this.frequency * (this.pricePerWord / 100);

                if(this.plan === 'a') myPrice = myPrice * 12;

                if(this.plan === 'o') myPrice += this.kwplan * this.pricekw

                this.price = Math.round(myPrice * 100) / 100;
            },
            CalculateDicountPrice(){

                if(this.pricechange === ''){
                    this.pricechangeppw = ''
                    this.pricediscount = ''
                    return
                }

                var myPrice = this.pricechange

                if(this.plan === 'o') myPrice -= this.kwplan * this.pricekw

                this.pricediscount = 100 - this.pricechange * 100 / this.price
                this.pricediscount =  Math.round((this.pricediscount ) * 100) / 100

                myPrice = this.pricechange

                if(this.plan === 'o') myPrice -= this.kwplan * this.pricekw
                if(this.plan === 'a')  myPrice = myPrice / 12

                this.pricechangeppw = (myPrice / this.words / this.frequency) * 100
                this.pricechangeppw = (Math.round(this.pricechangeppw * 1000) / 1000) ;
            }
        },
        watch:{
            'pricekw': function(val, preVal){
                this.CalculatePrice();
                this.CalculateDicountPrice()
            },
            'kwplan': function(val, preVal){
                this.CalculatePrice();
                this.CalculateDicountPrice()
            },
            'pricePerWord': function(val, preVal){
                this.CalculatePrice();
                this.CalculateDicountPrice()
            },
            'plan': function(val, preVal){
                this.CalculatePrice();
                this.CalculateDicountPrice()
            },
            'words': function(val, preVal){
                this.CalculatePrice();
                this.CalculateDicountPrice()
            },
            'frequency': function(val, preVal){

                this.CalculatePrice();
                this.CalculateDicountPrice()
            },
            'pricechange': function (val,preVal) {
                this.CalculateDicountPrice()

            }
        },
        computed:{
            priceContent(){
                var myPrice = this.frequency * this.words * this.pricePerWord

                if(this.plan === "a")
                    myPrice *= 12

                myPrice = myPrice / 100

                return Math.round(myPrice * 100) / 100;
            },
            priceTitle(){

                if(this.plan != "o")
                    return 0

                return this.pricekw * this.kwplan
            },
          Link(){

              var ppw = this.pricePerWord

              if(this.pricechangeppw !== '')
                  ppw = this.pricechangeppw

              var link = "https://access.steadycontent.com/vip?sid=" + localStorage.guid + "&ppw=" + ppw + "&w=" + this.words + "&f=" + this.frequency + "&pl=" + this.plan + "&kw=" + this.kwplan;


              if(this.email !== '')
                  link += "&em=" + this.email

              if(this.name !== '')
                  link += "&na=" + this.name

              if(this.plan === 'o' && this.kwplan > 0)
                  link += "&kwp=" + this.pricekw

              return link;
          }, LinkTrial(){
                var link = "https://trial.steadycontent.com/?sid=" + localStorage.guid;

                return link;
            }
        },
        mounted() {
            this.plans.push({ text: 'Monthly', value: 'm'});
            this.plans.push({ text: 'One time order (OTO)', value: 'o'});
            this.plans.push({ text: 'Annual', value: 'a'});

            this.kwplans.push({ text: '0 - No KW Research or Titles', value: 0});

            var i;
            for (i = 1; i < 60; i++) {
                this.kwplans.push({ text: i.toString() + ' - KW Research or Titles', value: i});
            }

        }
    }
</script>

<style>
    #boldme1, #boldme2,#boldme3{
        font-weight: bold !important;
        color: black !important;
    }
</style>
<style scoped>
    .col{
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
</style>
