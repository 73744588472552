<template>
  <v-app >
    <v-app-bar dark v-if="email !== ''" app class="app-bar">
      <v-container fluid fill-height>

        <v-layout row align-center>
          <v-flex fill-height xl2 lg2 md6 sm12 xs12 align="center" class="text-left pl-lg-12"  ><router-link :to="{ name: 'tabs'}"><img class="responsive-img" style="height: 100%;object-fit: contain" src="/images/logo.png" /></router-link></v-flex>
          <v-flex fill-height xl10 lg10 md6  align="center" style="line-height: 35px" class="hidden-sm-and-down text-right pr-lg-12 logout">{{fullName}} | <a href="javascript:void(0)" v-on:click="Logout($event)">Log out</a></v-flex>
        </v-layout>

      </v-container>
      <v-spacer></v-spacer>

    </v-app-bar>

    <v-content>
      <transition name="fade">
        <router-view @UserLoggedIn="UserLoggedIn"></router-view>
      </transition>
    </v-content>
  </v-app>
</template>

<script>

  import Vue from 'vue'
  import VueRouter from 'vue-router'
  import tabs from "./components/tabs";
  import linkBuilder from "./components/linkBuilder";
  import leadprofile from "./views/leadprofile"

  import login from './views/login'
  import {Utility} from "./api/utilities";
  import {LoginAPIClient} from "./api/LoginAPI";

  Vue.use(VueRouter);

  const router = new VueRouter({
    mode: 'history',
    routes: [
      { path: '/', component: tabs, name: "tabs" },
      { path: '/login', component: login, name: "login" },
      { path: '/linkBuilder', component: linkBuilder, name: "linkBuilder" },
      { path: '/leadprofile/:id', component: leadprofile, name: "leadprofile" },
    ]
  })


export default {
  name: 'App',
  router,
  components: {
    tabs,
    leadprofile,
    login
  },

  data: () => ({
    email: '',
    fullName: ''
  }),
  mounted(){


      if(localStorage.email !== ''){
        this.email = localStorage.email;
        this.fullName = localStorage.fullName;

      }


  },
  methods:{
    UserLoggedIn(){
      this.email = localStorage.email;
      this.fullName = localStorage.fullName;

      this.$router.push('/');
    },
    Logout(){
      localStorage.email = '';
      this.fullName = '';
      this.email = '';

      this.$router.push('/login');
    }
  }
};
</script>
<style scoped>


  .fade-enter-active, .fade-leave-active {
    transition-property: opacity;
    transition-duration: .25s;
  }

  .fade-enter-active {
    transition-delay: .25s;
  }

  .fade-enter, .fade-leave-active {
    opacity: 0
  }

  .logout a{
    color: white !important;
    text-decoration: none !important;
  }
</style>
