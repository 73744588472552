const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

var days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];

export const Utility = {

    prepareGoNullableFields: function(value){
      value = this.replaceAll(value, "{", "");
        value = this.replaceAll(value, "}", "");
        value = this.replaceAll(value, "true", "");
        value = this.replaceAll(value, "false", "");

        return value.trim();
    },

    formatDate: function(dateString) {
        var d = new Date(dateString);
        //return ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear();
        return ("0" + d.getMonth()).slice(-2) + "-" + ("0"+(d.getDate()+1)).slice(-2) + "-" + d.getFullYear();
    },

    formatDate2: function(dateString) {
        var d = new Date(dateString);
        //return ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear();
        return (days[d.getDay()] + "-" +monthNames[d.getMonth()] + "-" + ("0"+(d.getDate())).slice(-2) + "-" + d.getHours() + "00");
    },

    getBaseUrl: function () {
        return window.location.href.match(/^.*\//);
    },

    ifOnDev: function () {
        return window.location.href.includes("dev");
    },

    getQueryParameter: function (name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);


        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },

    replaceAll : function (str, find, replace) {
        return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
    },



    copyToClipboard: function(text) {
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return clipboardData.setData("Text", text);

    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
}
}
