<template>
    <v-row justify="center">
        <v-dialog  v-model="ShowAddLeadDialog" persistent max-width="740px">
            <template >

            </template>

            <v-card class="justify-center" style="border-radius: 15px 15px 0px 0px">
                <v-card-title class="text-left title-header pt-4 pb-4" style="border-bottom: 1px solid #cccccc;background-color: #7f9bbd;" >

                    <v-layout class="pl-2 pr-2" style="color: white;font-size: 0.9em;width:100%">Add New Lead</v-layout>

                </v-card-title>
                <v-card-text style="background-color: white;">
                    <v-container  class="pt-1 ">
                        <br/>
                        <v-row class="pl-0 pr-8 ml-n12 form">
                            <v-col lg="4"justify="center" row>
                                <label>Company Name:</label>
                            </v-col>
                            <v-col lg="8" md="12" sm="12" class="">
                                <input type="text" v-model="company" />
                            </v-col>

                            <v-col lg="4"justify="center" row>
                                <label>First Name:</label>
                            </v-col>
                            <v-col lg="8" md="12" sm="12" class="">
                                <input type="text" v-model="firstName" />
                            </v-col>


                            <v-col lg="4"justify="center" row>
                                <label>Last Name:</label>
                            </v-col>
                            <v-col lg="8" md="12" sm="12" class="">
                                <input type="text" v-model="lastName" />
                            </v-col>


                            <v-col lg="4"justify="center" row>
                                <label>Email Account:</label>
                            </v-col>
                            <v-col lg="8" md="12" sm="12" class="">
                                <input type="text" v-model="email" />
                            </v-col>

                            <v-col lg="4"justify="center" row>
                                <label>Phone:</label>
                            </v-col>
                            <v-col lg="8" md="12" sm="12" class="">
                                <input type="text" v-model="phone" />
                            </v-col>

                            <v-col lg="4"justify="center" row>
                                <label>Website:</label>
                            </v-col>
                            <v-col lg="8" md="12" sm="12" class="">
                                <input type="text" v-model="website" />
                            </v-col>

                            <v-col lg="4"justify="center" row>
                                <label>Niche:</label>
                            </v-col>
                            <v-col lg="8" md="12" sm="12" class="">
                                <input type="text" v-model="niche" />
                            </v-col>



                        </v-row>
                        <br>
                        <v-row  class="pl-6 pr-9">

                            <v-col class="col text-center" lg="6" md="6" sm="6" >
                                <v-btn color="white" style="color: #7f9bbd !important;" light block @click="Cancel()">Cancel</v-btn>
                            </v-col>

                            <v-col class="col text-center" lg="6" md="5" sm="6" >
                                <v-btn color="#7f9bbd" dark block @click="Submit()">Submit</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>

</template>

<script>
    import {MainPageAPIClient} from "../api/HomePageAPI";

    export default {
        name: "addLead",
        data:function(){
            return {
                company: '',
                firstName: '',
                lastName: '',
                website: '',
                email : '',
                niche: '',
                keywords: '',
                phone: ''
            }
        },
        methods:{
            Submit(){
                alert("Not implemented yet. Coming soon");
                /*MainPageAPIClient.createLead(this.email, this.firstName + ' ' + this.lastName, "-", "SalesApp", this.phone, this.website, this.niche, this.keywords, '-', 'Trial').then(
                    (data) =>{
                        this.$emit('update:ShowAddLeadDialog', false)
                    }
                )*/
            },
            Cancel(){
                this.$emit('update:ShowAddLeadDialog', false)
            }
        },
        props:{
            ShowAddLeadDialog: {
                default: false
            },
        }
    }
</script>

<style scoped>
.form .col{
    padding-bottom: 10px !important;
    margin-bottom: 0px !important;
}

    input{
        width:100%;
        line-height: 45px;
        border-radius: 3px;
        border:1px solid #dde4ed !important;
        max-height: 42px;
        padding-left: 10px;
        padding-right: 10px;
    }

    label{
        line-height: 42px;
        margin-left: 60px;
        font-size: 16px;
        color: #4a4a4a;
        font-weight:normal;
    }

    .v-btn{
        height: 45px !important;
        font-size: 18px !important;
        text-transform: none !important;
    }

</style>
