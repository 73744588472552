<template>
    <div>
        <v-flex>
            <v-row>
                <v-col class="font-weight-bold text-right" lg="2">
                    Name:
                </v-col>
                <v-col lg="7">
                    {{name}}
                </v-col>
            </v-row>
            <v-row>
                <v-col class="font-weight-bold text-right" lg="2">
                    Email:
                </v-col>
                <v-col lg="7">
                    {{email}}
                </v-col>
            </v-row>
            <v-row>
                <v-col class="font-weight-bold text-right" lg="2">
                    Connected to Stripe:
                </v-col>
                <v-col lg="7">
                    <span class="font-weight-bold" style="color:#739947"  v-if="connected">
                        Yes
                    </span>
                    <span class="font-weight-bold" style="color:Red"  v-if="!connected">
                        No
                    </span>
                    <span>
                        <a class="blueBtn ml-3" v-on:click="GetStripeLink()">Connect to Stripe</a>
                    </span>
                </v-col>
            </v-row>
        </v-flex>
    </div>
</template>

<script>
    import {LoginAPIClient} from "../api/LoginAPI";

    export default {
        name: "profile",
        data: function () {
            return{
                name: '',
                stripeId: '',
                connected: false,
                guid: '',
                email: ''
            }
        },
        computed:{

        },
        methods:{
            GetStripeLink(){
                window.location = "https://admin.steadycontent.com/sales/stripe/connect.aspx?spg=" + this.guid;
            }
        },
        mounted() {
            this.name = localStorage.fullName;
            this.email = localStorage.email;
            LoginAPIClient.getStripeId(localStorage.email).then(
                (data) =>{

                    if( data.data.salesPerson.StripeID !== undefined && data.data.salesPerson.StripeID !== null){
                        this.stripeId = data.data.salesPerson.StripeID;

                        this.connected = true;
                    }
                    else{
                        this.connected = false;
                        this.stripeId = '';
                    }

                    this.guid = data.data.salesPerson.guid;

                }
            );
        }
    }
</script>

<style scoped>
    .blueBtn{
        border-radius: 17.5px;
        box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
        background-color: #2388d9;
        border: solid 1px #4a90e2;
        color: white;
        text-decoration: none;
        padding: 5px 25px;
    }


</style>
